<div class="header-container p-d-flex p-ai-center p-jc-between p-px-3 p-py-2">

    <div class="left-buttons p-d-flex p-ai-center">
        <p-button *ngIf="showMainButtons.return" icon="pi pi-arrow-left" pTooltip="Voltar" tooltipPosition="bottom" class="button-spacing"
            (onClick)="navigateLastPage()"></p-button>

        <div class="button-spacing" style="position: relative;">

            <p-button left-buttons
                *ngIf="showMainButtons.history"
                icon="pi pi-book"
                pTooltip="Histórico"
                tooltipPosition="bottom"
                class="button-spacing"
                [severity]="temMensagemNaoVisualizada ? 'warning' : null"
                (onClick)="handleHistoricoClick()"
                [disabled]="desabilitarBotaoHistorico"
            />
            <span
            *ngIf="temMensagemNaoVisualizada"
            class="pi pi-info"
            style="
                scale: 1.4;
                position: absolute;
                right: 0px;
                top: -4px;
                color: red;
                rotate: 180deg;"></span>
        </div>

    </div>

    <div class="header-title p-text-center p-flex-grow-1">
        <h3 class="header-title-text">{{ title }}</h3>
    </div>

    <div class="right-buttons p-d-flex p-ai-center">
        <ng-container *ngFor="let button of buttons">
            <ng-container *ngIf="button.split; else regularButton">
                <p-splitButton
                    [label]="button.label"
                    [icon]="button.icon"
                    [pTooltip]="button.tooltip"
                    tooltipPosition="bottom"
                    [model]="button.model"
                    [disabled]="button.disabled">
                </p-splitButton>
            </ng-container>
            <ng-template #regularButton>
                <!-- Renderiza o p-button padrão -->
                <p-button
                    [label]="button.label"
                    [icon]="button.icon"
                    [pTooltip]="button.tooltip"
                    tooltipPosition="bottom"
                    class="button-spacing"
                    [disabled]="button.disabled"
                    (onClick)="button.onClick && button.onClick()">
                </p-button>
            </ng-template>
        </ng-container>
    </div>


    <div class="table-container">
        <ng-content></ng-content>
    </div>
</div>
