<div class="surface-0 flex justify-content-center align-items-center" style="min-height: 100vh;">
    <div class="flex flex-column align-items-center text-center py-6 px-4" style="max-width: 400px; width: 100%;">

        <a href="https://www.fesfsus.ba.gov.br/" target="_blank" class="mb-6">
            <img src="assets/images/fesf/LogoFESFSUS.png" alt="Logo" class="w-full" />
        </a>

        <div class="pb-8 px-4">
            <h4 class="m-0" style="line-height: 22px;">
                Sistema de Gestão de Partos Humanizados
            </h4>
        </div>

        <form class="flex flex-column w-full" [formGroup]="loginForm">
            <span class="p-input-icon-left w-full mb-4">
                <i class="pi pi-envelope"></i>
                <input
                    id="email"
                    type="text"
                    formControlName="email"
                    pInputText
                    class="w-full text-color-secondary surface-50 border-200"
                    placeholder="Email"
                />
            </span>
            <span class="p-input-icon-left w-full mb-4">
                <i class="pi pi-lock"></i>
                <input
                    id="senha"
                    type="password"
                    formControlName="senha"
                    pInputText
                    class="w-full text-color-secondary surface-50 border-200"
                    placeholder="Senha"
                />
            </span>
            <button
                pButton
                pRipple
                label="Entrar"
                class="w-full mb-4"
                (click)="onSubmit()"
            ></button>
            <a href="alterar-senha" class="font-medium text-sm text-300">Esqueceu a senha?</a>
        </form>
    </div>
</div>

<div class="fixed-footer">
    <h6 class="m-0 font-medium text-300" style="line-height: 17px;">
        Copyright Ⓒ
        <a href="https://www.fesfsus.ba.gov.br/" target="_blank" class="text-300">FESF</a> /
        <a href="https://gsort.ifba.edu.br/" target="_blank" class="text-300">GSORT</a>&#64;<a href="https://portal.ifba.edu.br/" target="_blank" class="text-300">IFBA</a>
    </h6>
</div>
