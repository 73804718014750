import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Gpq } from '../../models/Gpq';
import { GpqDocumentosStatus } from '../../models/GpqDocumentosStatus';
import { HistoricoGpq } from '../../models/HistoricoGpt';
import { Municipio } from '../../models/Municipio';

@Injectable({
    providedIn: 'root',
})
export class GpqService {
    private apiUrl = environment.API_URL + 'gpq';
    private tipoDaVisitaParaDownload: string = ''

    private gpqSubject: BehaviorSubject<Gpq | null> =
        new BehaviorSubject<Gpq | null>(null);
    private gpq$ = this.gpqSubject.asObservable();

    constructor(private http: HttpClient) {}

    private setHeadersConfiguration() {
        let token = sessionStorage.getItem('token');
        return new HttpHeaders({
            secret: environment.API_SECRET,
            Authorization: `Bearer ${token}`,
        });
    }

    listarGpq(): Observable<Gpq[]> {
        const headers = this.setHeadersConfiguration();
        return this.http
            .get<Gpq[]>(this.apiUrl, {
                headers: headers,
            })
            .pipe(
                tap((gpqs: Gpq[]) => {
                    const gpqIdAtual =
                        this.gpqSubject.getValue()?.numeroDoDocumento;
                    if (gpqIdAtual) {
                        const gpq = gpqs.find(
                            (g) => g.numeroDoDocumento == Number(gpqIdAtual)
                        );
                        if (gpq) this.atualizarGpq(gpq);
                    }
                })
            );
    }

    listarGpqEnfermeira(): Observable<Gpq[]> {
        const headers = this.setHeadersConfiguration();
        return this.http
            .get<Gpq[]>(this.apiUrl + '/enfermeira', {
                headers: headers,
            })
            .pipe(
                tap((gpqs: Gpq[]) => {
                    const gpqIdAtual =
                        this.gpqSubject.getValue()?.numeroDoDocumento;
                    if (gpqIdAtual) {
                        const gpq = gpqs.find(
                            (g) => g.numeroDoDocumento == Number(gpqIdAtual)
                        );
                        if (gpq) {
                            if(gpq.statusDoDocumento == 'Bloqueado') {
                                return this.limparGpq()
                            }
                            return this.atualizarGpq(gpq);
                        }
                    }
                })
            );
    }

    private limparGpq() {
        sessionStorage.removeItem('gpq')
    }

    listarGpqEnfermeiraDadoMes(data: Date): Observable<Gpq[]>{
        const headers = this.setHeadersConfiguration();
        const localDate = data.toISOString().split('T')[0];

        return this.http.get<Gpq[]>(this.apiUrl + '/mes/'+ localDate, {
          headers: headers
        }).pipe(tap((gpqs: Gpq[]) => {
          const gpqIdAtual = this.gpqSubject.getValue()?.numeroDoDocumento
          if(gpqIdAtual){
            const gpq = gpqs.find((g) => g.numeroDoDocumento == Number(gpqIdAtual))
            if(gpq)
              this.atualizarGpq(gpq)
          }
        }));
    }

    criarNovoGpq(): Observable<Gpq> {
        const headers = this.setHeadersConfiguration();
        return this.http.post<Gpq>(
            this.apiUrl,
            {},
            {
                headers: headers,
            }
        );
    }

    criarGpqFerias(): Observable<Gpq> {
        const headers = this.setHeadersConfiguration();
        return this.http.post<Gpq>(
            this.apiUrl + '/ferias',
            {},
            {
                headers: headers,
            }
        );
    }

    listarGpqPorMunicipio(municipio: Municipio): Observable<Gpq[]> {
        const headers = this.setHeadersConfiguration();
        return this.http
            .get<Gpq[]>(this.apiUrl + '/municipio/' + municipio.codigo, {
                headers: headers,
            })
            .pipe(
                tap((gpqs: Gpq[]) => {
                    const gpqIdAtual =
                        this.gpqSubject.getValue()?.numeroDoDocumento;
                    if (gpqIdAtual) {
                        const gpq = gpqs.find(
                            (g) => g.numeroDoDocumento == Number(gpqIdAtual)
                        );
                        if (gpq) this.atualizarGpq(gpq);
                    }
                })
            );
    }

    listarGpqDetalhado(gpqId: number): Observable<any> {
        const headers = this.setHeadersConfiguration();
        return this.http.get(this.apiUrl + '/' + gpqId, {
            headers: headers,
        });
    }

    enviarRespostasGpq(gpqId: number, respostas: any[]): Observable<any> {
        const headers = this.setHeadersConfiguration();
        return this.http.put<Gpq>(
            this.apiUrl + '/respostas/' + gpqId,
            { respostas: respostas },
            {
                headers: headers,
            }
        );
    }

    validarGpq(
        gpqId: number,
        descricao: string,
        acao: string
    ): Observable<Gpq> {
        const headers = this.setHeadersConfiguration();
        return this.http
            .patch<Gpq>(
                this.apiUrl + '/validar/' + gpqId,
                {
                    descricao: descricao,
                    acao: acao,
                },
                {
                    headers: headers,
                }
            )
            .pipe(
                tap((gpq: Gpq) => {
                    sessionStorage.setItem('gpq', JSON.stringify(gpq));
                    this.atualizarGpq(gpq);
                })
            );
    }

    enviarMensagem(gpqId: number, descricao: string): Observable<HistoricoGpq> {
        const headers = this.setHeadersConfiguration();
        return this.http.patch<HistoricoGpq>(this.apiUrl + '/mensagem/' + gpqId,{
                    descricao: descricao,
                    acao: "Mensagem",
                },{
                    headers: headers,
                }
            );
    }

    listarGpqPeloUsuarioMunicipal(): Observable<Gpq[]> {
        const headers = this.setHeadersConfiguration();
        return this.http
            .get<Gpq[]>(this.apiUrl + '/meuSpnh', {
                headers: headers,
            })
            .pipe(
                tap((gpqs: Gpq[]) => {
                    const gpqIdAtual =
                        this.gpqSubject.getValue()?.numeroDoDocumento;
                    if (gpqIdAtual) {
                        const gpq = gpqs.find(
                            (g) => g.numeroDoDocumento == Number(gpqIdAtual)
                        );
                        if (gpq) this.atualizarGpq(gpq);
                    }
                })
            );
    }

    listarGpqParaAprovar(): Observable<Gpq[]> {
        const headers = this.setHeadersConfiguration();
        return this.http.get<Gpq[]>(this.apiUrl + '/paraAprovar', {
            headers: headers,
        });
    }

    enviarAnexo(gpqId: number, file: File): Observable<any> {
        const headers = this.setHeadersConfiguration();

        const formData = new FormData();
        formData.append('file', file);

        return this.http.post<any>(this.apiUrl + '/anexos/' + gpqId, formData, {
            headers: headers,
        });
    }

    downloadAnexo(anexoId: number): Observable<Blob> {
        const headers = this.setHeadersConfiguration();
        return this.http.get<Blob>(this.apiUrl + '/anexos/' + anexoId, {
            headers: headers,
            responseType: 'blob' as 'json',
        });
    }

    removerAnexo(anexoId: number): Observable<void> {
        const headers = this.setHeadersConfiguration();
        return this.http.delete<void>(this.apiUrl + '/anexos/' + anexoId, {
            headers: headers,
        });
    }

    selecionarGpq(gpq: Gpq) {
        sessionStorage.setItem('gpq', JSON.stringify(gpq));
        this.atualizarGpq(gpq);
    }

    private atualizarGpq(gpq: Gpq): void {
        this.gpqSubject.next(gpq);
    }

    enviarAssinaturaVisita(
        gpqId: number,
        file: File
        //tipoVisita: string
    ): Observable<any> {
        const headers = this.setHeadersConfiguration();

        const formData = new FormData();
        formData.append('file', file);

        return this.http.post<any>(
            this.apiUrl + '/' + gpqId + '/assinatura/' + this.tipoDaVisitaParaDownload,
            formData,
            {
                headers: headers,
            }
        );
    }

    removerAssinaturaVisita(
        gpqId: number,
        tipoVisita: string
    ): Observable<any> {
        const headers = this.setHeadersConfiguration();
        return this.http.delete<any>(
            this.apiUrl + '/' + gpqId + '/assinatura/' + tipoVisita,
            {
                headers: headers,
            }
        );
    }

    downloadAssinatura(gpqId: number, tipoFormulario: string, reuniaoId: number | string = ""): Observable<HttpResponse<Blob>> {
        const headers = this.setHeadersConfiguration();
        const params = {
            reuniaoId: reuniaoId!
        }

        return this.http.get<Blob>(
            this.apiUrl + '/' + gpqId + '/assinatura/' + tipoFormulario + '/download',
            {
                headers: headers,
                params: params,
                responseType: 'blob' as 'json',
                observe: 'response'
            }
        );
    }

    temAssinaturaVisita(
        gpqId: number,
        tipoVisita: string
    ): Observable<Boolean> {
        const headers = this.setHeadersConfiguration();
        return this.http.get<Boolean>(
            this.apiUrl + '/' + gpqId + '/assinatura/' + tipoVisita,
            {
                headers: headers,
            }
        );
    }

    getGpq(subscribeGpq: (value: Gpq | null) => void): Subscription {
        // Caso o GPQ seja nulo, como no caso do usuário atualizar a página, será buscado no storage um GPQ em cache
        if (!this.gpqSubject.getValue()) {
            const gpqStorage = sessionStorage.getItem('gpq');
            if (gpqStorage) {
                const cachedGpq: Gpq = JSON.parse(gpqStorage);
                this.atualizarGpq(cachedGpq);
            }
        }

        return this.gpq$.subscribe(subscribeGpq);
    }

    enviarAssinaturaReuniao(gpqId: number, file: File, tipoReuniao: string, reuniaoId: number) {
        const headers = this.setHeadersConfiguration();
        const params = {
            reuniaoId: reuniaoId
        }

        const formData = new FormData();
        formData.append('file', file);

        return this.http.post<any>(
            this.apiUrl + '/' + gpqId + '/assinatura/' + tipoReuniao,
            formData,
            {
                headers: headers,
                params: params
            }
        );
    }

    removerAssinaturaReuniao( gpqId: number, tipoReuniao: string, reuniaoId: number): Observable<any> {
        const headers = this.setHeadersConfiguration();
        const params = {
            reuniaoId: reuniaoId
        }

        return this.http.delete<any>(
            this.apiUrl + '/' + gpqId + '/assinatura/' + tipoReuniao,
            {
                headers: headers,
                params: params
            }
        );
    }

    temAssinaturaReuniao(gpqId: number, tipoReuniao: string, reuniaoId: number) {
        const headers = this.setHeadersConfiguration();
        const params = {
            reuniaoId: reuniaoId
        }

        return this.http.get<Boolean>(
            this.apiUrl + '/' + gpqId + '/assinatura/' + tipoReuniao,
            {
                headers: headers,
                params: params
            }
        );
    }

    listarGpqPorMes(data: Date): Observable<Gpq[]> {
        const headers = this.setHeadersConfiguration();
        const params = new HttpParams()
            .set('mes', data.getMonth() + 1)
            .append('ano', data.getFullYear());
        return this.http.get<Gpq[]>(this.apiUrl + '/mensal', {
            headers: headers,
            params: params,
        });
    }

    votacaoMunicipal(gpqId: number) {
        const headers = this.setHeadersConfiguration();
        return this.http.get(this.apiUrl + `/${gpqId}/votacao-municipal`, {
            headers: headers
        })
    }


    getStatusGpqDocumentos(gpqId: number): Observable<GpqDocumentosStatus> {
        const headers = this.setHeadersConfiguration();
        return this.http.get<GpqDocumentosStatus>(this.apiUrl + '/' + gpqId + '/status', {
            headers: headers,
        });
    }

    getGpqsAbertosDeEnfermeira(enfoId: string): Observable<Gpq[]> {
        return this.http.get<Gpq[]>(this.apiUrl + '/open/' + enfoId, {
            headers: this.setHeadersConfiguration(),
        });
    }

    alterarStatusGpqCriadoFerias(
        gpqId: number,

    ): Observable<Gpq> {
        const headers = this.setHeadersConfiguration();
        return this.http
            .patch<Gpq>(
                this.apiUrl + '/' + gpqId + '/alterar-status',{},
                {
                    headers: headers,
                }
            )
            .pipe(
                tap((gpq: Gpq) => {
                    sessionStorage.setItem('gpq', JSON.stringify(gpq));
                    this.atualizarGpq(gpq);
                })
            );
    }

    setTipoVisitaDownload(tipo: string){
        this.tipoDaVisitaParaDownload = tipo;
    }
}
