import { Component, Input } from '@angular/core';

import { GpqDocumentosStatus } from 'src/app/application/models/GpqDocumentosStatus';

@Component({
    selector: 'app-painel-formularios',
    templateUrl: './painel-formularios.component.html',
    styleUrl: './painel-formularios.component.scss'
})
export class PainelFormulariosComponent {
    formularioItems: any[] = [];
    @Input() gpqDocumentosStatus!: GpqDocumentosStatus;
    isLoading: boolean = true;

    constructor() { }

    ngOnInit(): void {
        this.preencherListaFormularios();
        this.isLoading = false;

    }

    getClass(status: boolean | null): string {
        if (status === true) {
            return 'background-color-ready';
        } else if (status === false) {
            return 'background-color-not-ready';
        } else {
            return 'background-color-parcially-ready';
        }
    }

    getTooltip(status: boolean | null): string {
        if (status === true) {
            return 'Pronto para envio.';
        } else if (status === false) {
            return 'Ausente de Registro ou Confirmação.';
        } else {
            return 'Ausente de Assinatura.';
        }
    }

    statusDoDocumento(item: string): boolean | null {
        switch (item) {
            case 'Visitas de Vinculação':
                if(this.gpqDocumentosStatus?.ausenciaDeVinculacao === true ||
                    this.gpqDocumentosStatus?.ausenciaDeVinculacao === false && this.gpqDocumentosStatus.temAssinaturaVinculacao === true) {
                    return true;
                } else if (this.gpqDocumentosStatus?.ausenciaDeVinculacao === false && this.gpqDocumentosStatus.temAssinaturaVinculacao === false) {
                    return null;
                }

                return false;

            case 'Consultas de Pré-Natal':
            case 'Consultas de Pré-Natal':
                if(this.gpqDocumentosStatus?.ausenciaDePreNatal === true ||
                    this.gpqDocumentosStatus?.ausenciaDePreNatal === false && this.gpqDocumentosStatus.temAssinaturaPreNatal === true) {
                    return true;
                } else if (this.gpqDocumentosStatus?.ausenciaDePreNatal === false && this.gpqDocumentosStatus.temAssinaturaPreNatal === false) {
                    return null;
                }

                return false;
            case 'Educação Permanente':
                if(this.gpqDocumentosStatus?.ausenciaDeEducacaoPermanente === true ||
                    this.gpqDocumentosStatus?.ausenciaDeEducacaoPermanente === false && this.gpqDocumentosStatus.temAssinaturaEducacaoPermanente === true) {
                    return true;
                } else if (this.gpqDocumentosStatus?.ausenciaDeEducacaoPermanente === false && this.gpqDocumentosStatus.temAssinaturaEducacaoPermanente === false) {
                    return null;
                }

                return false;
            case 'Encontro com grupo de Mulheres':
            case 'Encontro com grupo de Mulheres':
                if(this.gpqDocumentosStatus?.ausenciaDeEncontroGestantes === true ||
                    this.gpqDocumentosStatus?.ausenciaDeEncontroGestantes === false && this.gpqDocumentosStatus.temAssinaturaEncontroGestantes === true) {
                    return true;
                } else if (this.gpqDocumentosStatus?.ausenciaDeEncontroGestantes === false && this.gpqDocumentosStatus.temAssinaturaEncontroGestantes === false) {
                    return null;
                }

                return false;
            case 'Reunião de Monitoramento':
            case 'Reunião de Monitoramento':
                if(this.gpqDocumentosStatus?.ausenciaDeReuniaoMonitoramento === true ||
                    this.gpqDocumentosStatus?.ausenciaDeReuniaoMonitoramento === false && this.gpqDocumentosStatus.temAssinaturaReuniaoMonitoramento === true) {
                    return true;
                } else if (this.gpqDocumentosStatus?.ausenciaDeReuniaoMonitoramento === false && this.gpqDocumentosStatus.temAssinaturaReuniaoMonitoramento === false) {
                    return null;
                }

                return false;
            case 'Partos do Mês':
            case 'Partos do Mês':
                return this.gpqDocumentosStatus?.ausenciaDePartos !== null;
            case 'Gestações de Risco':
            case 'Gestações de Risco':
                return this.gpqDocumentosStatus?.ausenciaDeGestacaoDeRisco !== null;
            case 'Partos de Risco':
                return this.gpqDocumentosStatus?.ausenciaDePartoDeRisco !== null;

            default:
                return false;
        }
    }

    private preencherListaFormularios() {
        this.formularioItems = [
            {
                label: 'Consultas de Pré-Natal',
                icon: 'pi pi-file-o',
                routerLink: ['/visita', 'pre-natal'],
            },
            {
                label: 'Visitas de Vinculação',
                icon: 'pi pi-fw pi-link',
                routerLink: ['/visita', 'vinculacao'],
            },
            {
                label: 'Encontro com grupo de Mulheres',
                icon: 'pi pi-fw pi-book',
                routerLink: ['/reuniao', 'encontro-gestantes'],
            },
            {
                label: 'Educação Permanente',
                icon: 'pi pi-fw pi-book',
                routerLink: ['/reuniao', 'educacao-permanente'],
            },
            {
                label: 'Reunião de Monitoramento',
                icon: 'pi pi-fw pi-book',
                routerLink: ['/reuniao', 'reuniao-monitoramento'],
            },
            {
                label: 'Partos do Mês',
                icon: 'pi pi-fw pi-book',
                routerLink: ['/partos'],
            },
            {
                label: 'Gestações de Risco',
                icon: 'pi pi-file-o',
                routerLink: ['/riscos', 'gestacao'],
            },
            {
                label: 'Partos de Risco',
                icon: 'pi pi-fw pi-exclamation-triangle',
                routerLink: ['/riscos', 'parto'],
            },
        ];
    }
}
