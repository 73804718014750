import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Municipio } from 'src/app/application/models/Municipio';
import { GpqService } from 'src/app/application/services/gpq-service/gpq.service';
import { HistoricoService } from 'src/app/application/services/historico-service/historico.service';
import { RelatorioService } from 'src/app/application/services/relatorio-service/relatorio.service';
import { StatusGpq } from 'src/app/core/models/StatusGpqEnum';
import { UserRole } from 'src/app/core/models/UserRoles';
import { ExportRelatorioMensalPdfService } from 'src/app/core/service/export/export-relatorio-mensal-pdf/export-relatorio-mensal-pdf.service';
import { DataFormatService } from 'src/app/core/service/format-service/data-format/data-format.service';
import { PartoHumanizadoMessageService } from 'src/app/core/service/parto-humanizado-message.service';

@Component({
  selector: 'app-validar-gpq',
  templateUrl: './validar-gpq.component.html',
  styleUrl: './validar-gpq.component.scss'
})
export class ValidarGpqComponent implements OnInit {

  buttons: any[] = [];
  buttonsGpqStatusFerias: any[] = [];

  activeIndex: number = 0
  value: string = ''

  gpqId!: number;
  data!: string;

  municipio!: Municipio
  dataReferenteGpq!: Date;

  role: string = ''
  items: MenuItem[];
  podeValidar: boolean = false;
  usuarioValidou: boolean = false;
  temMensagemNaoVisualizada: boolean = false;
  statusGpq: string = StatusGpq.Criado;
  visibleDialogStatus: boolean = false;

  relatorioMensal: any = null;

    constructor(
      private messageService: PartoHumanizadoMessageService,
      private route: ActivatedRoute,
      private gpqService: GpqService,
      private location: Location,
      private router: Router,
      private historicoService: HistoricoService,
      private relatorioService: RelatorioService,
      private exportRelatorioMensalPdfService: ExportRelatorioMensalPdfService,
      private dataFormatService: DataFormatService

    ) {
        this.items = [
          {
            label: 'Aprovar',
            command: () => this.aprovarGpq()
          },
          { separator: true },
          {
            label: 'Reprovar',
            command: () => this.showDialog()
          },
        ];
    }

    ngOnInit(): void {

      let role = sessionStorage.getItem('role-gpq')
      this.role = role ?? '';

      this.usuarioValidou = false;
      this.podeValidar = this.role === 'validador' && !this.usuarioValidou;

      this.gpqService.getGpq((gpq) => {
        if(!gpq)
          return;

        const roleUsuario = sessionStorage.getItem('role');

        if (roleUsuario == UserRole.ENFERMEIRA
            && (gpq.statusDoDocumento == StatusGpq.Criado
            || gpq.statusDoDocumento == StatusGpq.Rejeitado)){

            this.router.navigate(['home-enfermeira']);
            return;
        }

        this.gpqId = gpq.numeroDoDocumento
        this.buscarMensagens();
        this.data = this.alterarFormatoData(gpq.dataReferente.toString())
        this.municipio = gpq.municipio;
        this.dataReferenteGpq = gpq.dataReferente;
        this.statusGpq = gpq.statusDoDocumento;

        this.relatorioService
          .gerarRelatorio(gpq.municipio.codigo, new Date(gpq.dataReferente))
          .subscribe((relatorio) => {
              this.relatorioMensal = relatorio;

        });
      })

      this.buttons = [
        {
            icon: 'pi pi-file-pdf',
            tooltip: 'Exportar PDF',
            onClick: () => this.exportarRelatorio()
        },
        {
          icon: 'pi pi-check',
          tooltip: 'Validar GPQ',
          label: 'Validar GPQ:',
          split: true,
          model: this.items,
          disabled: !this.podeValidar
        }

      ];

        this.buttonsGpqStatusFerias = [
            {
                icon: 'pi pi-th-large',
                tooltip: 'Alterar Status da GPQ',
                onClick: () => this.showHideAlterarStatusDialog(),
            }
        ];
    }

    onUsuarioValidouChange(usuarioValidou: boolean){
      this.usuarioValidou = usuarioValidou
      this.podeValidar = this.role == 'validador' && this.usuarioValidou == false
    }

    alterarFormatoData(dataGpq: string): string{

      let data = new Date(dataGpq);
      const mes = data.getUTCMonth() + 1;
      const ano = data.getUTCFullYear();
      const mesFormatado = mes.toString().padStart(2, '0');
      return `${mesFormatado}/${ano}`;
    }

    aprovarGpq(){
      this.gpqService.validarGpq(this.gpqId, "", "Aceitacao").subscribe({
        next: () => {
          this.usuarioValidou = true
          this.showSuccess()
          this.location.back()

        },
        error: (response) => this.showError(response.error.message ?? "Erro ao aprovar a GPQ, tente novamente.")
      })
    }

    reprovarGpq() {
        this.gpqService.validarGpq(this.gpqId, this.value, "Rejeicao").subscribe({
          next: () => {
            this.usuarioValidou = false;
            this.showSuccessReprovacaoGpq()
            this.location.back();
          },
          error: (error) => this.showError(error.error.message)
        });
      }

    private buscarMensagens(){
      this.historicoService.novaMensage(this.gpqId).subscribe({
          next: (response) => this.temMensagemNaoVisualizada = response,
          error: (response) => this.messageService.showErrorMessage('Error', 'Não foi possível buscar mensagens pendentes.')
      })
    }

    visible: boolean = false;

    showDialog() {
        this.visible = true;
    }

    showSuccess() {
      this.messageService.showSuccessMessage('Sucesso', 'Sua validação foi registrada. GPQ aprovada com sucesso!')
    }

    showSuccessReprovacaoGpq(){
        this.messageService.showSuccessMessage('Sucesso', 'Sua validação foi registrada. GPQ foi reprovada!')
    }

    showError(message: string){
      this.messageService.showErrorMessage('Erro', message)
    }

    showWarning(){
      this.messageService.showWarnMessage('Aviso', 'Insira uma descrição para submeter sua validação.')
    }

    navigateHistoricoGpq(){
      this.router.navigate(['historico-gpq'])
    }

    tooltipValidacao(): string {
      if(this.podeValidar)
        return "Valide a GPQ."

      if(this.usuarioValidou)
        return "Você já validou esta GPQ."

      return "Validação fora de sua jurisdição."
    }

    exportarRelatorio() {
        if (!this.validarDados()) {
            this.messageService.showErrorMessage(`Error`, `Dados inválidos para a exportação do relatório`)
            return;
        }

        this.exportRelatorioMensalPdfService.exportarPdf(
            this.relatorioMensal!,
            new Date(this.dataReferenteGpq)
        );
    }


    private validarDados(): boolean {
        if (!this.relatorioMensal || !this.dataReferenteGpq) {
            this.messageService.showErrorMessage(`Error`, `Relatório mensal ou data referente não estão definidos.`)
            return false;
        }

        if (!this.relatorioMensal.nomeDoMunicio) {
            this.messageService.showErrorMessage(`Error`, `Nome do município inválido.`)
            return false;
        }
        return true;
    }



    navigateLastPage() {
        window.history.back();
      }

      confirmarReprovacao() {
        if (this.value.trim() === '') {
          this.showWarning();
        } else {
          this.reprovarGpq();
          this.visible = false;
        }
    }

    showHideAlterarStatusDialog() {
        this.visibleDialogStatus = !this.visibleDialogStatus;
    }

    alterarStatusGpq(){
        this.gpqService.alterarStatusGpqCriadoFerias(this.gpqId).subscribe({
            next: () => {
                this.messageService.showSuccessMessage('Sucesso', 'O status da GPQ foi alterado.')
                this.router.navigate(['formulario-gpq'])
            },
            error: () => {
                this.messageService.showErrorMessage('Erro', 'Ocorreu um erro durante a operação.')
            }
        })
    }
}
