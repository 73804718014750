@if(listaGpq.length > 0){

    <p-table
        [value]="listaGpq"
        #dt1
        selectionMode="single"
        [(selection)]="gpqSelecionado"
        [paginator]="true"
        [rows]="5"
        [rowsPerPageOptions]="[5, 10, 20]"
        [lazyLoadOnInit]="true"
        [scrollable]="true"
        scrollHeight="200px"
        [tableStyle]="{ 'min-width': '50em' }"
        [globalFilterFields]="[
            'nomeDaEnfermeira',
            'municipio.nome',
            'statusDoDocumento'
        ]"
        dataKey="numeroDoDocumento"
        [totalRecords]="listaGpq.length"
    >
        <ng-template pTemplate="header">
            <tr>
                <th></th>
                <th>Município</th>
                <th>Responsável</th>
                <th>Status</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="caption">
            <div class="flex">
                <p-iconField iconPosition="left" class="ml-auto">
                    <input
                        pInputText
                        type="text"
                        [(ngModel)]="searchValueGpqs"
                        (input)="onGlobalFilter(dt1, $event)"
                        placeholder="Buscar..."
                    />
                </p-iconField>
            </div>
        </ng-template>
        <ng-template pTemplate="body" let-gpq>
            <tr style="height: 60px">
                @if(gpq.statusDoDocumento != 'Ferias' && gpq.statusDoDocumento !=
                'Bloqueado'){
                <td>
                    <p-tableRadioButton [value]="gpq" (click)="selecionaGpq(gpq)" />
                </td>
                <td style="cursor: pointer" (click)="navigateToGpq(gpq)">
                    {{ gpq.municipio.nome }}
                </td>
                <td style="cursor: pointer" (click)="navigateToGpq(gpq)">
                    {{ gpq.nomeDaEnfermeira }}
                </td>
                <td style="cursor: pointer" (click)="navigateToGpq(gpq)">
                    <p-tag
                        [value]="getStatusExibicao(gpq.statusDoDocumento)"
                        [severity]="getStatusGpq(gpq)"
                        [style.left.px]="4"
                        [style.top.px]="4"
                    />
                </td>
                }@else{
                <td></td>
                <td>{{ gpq.municipio.nome }}</td>
                <td>{{ gpq.nomeDaEnfermeira }}</td>
                <td>
                    <p-tag
                        [value]="getStatusExibicao(gpq.statusDoDocumento)"
                        [severity]="getStatusGpq(gpq)"
                        [style.left.px]="4"
                        [style.top.px]="4"
                    />
                </td>
                }
            </tr>
        </ng-template>
    </p-table>
    } @else {

    <div class="flex justify-content-center flex-wrap">
        <div
            class="flex align-items-center justify-content-center font-bold border-round m-2 mt-5"
        >
            Nenhum registro de GPQ encontrado para este status.
        </div>
    </div>
    }
